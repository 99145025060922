<template>
  <v-flex xs12 sm6 md4 lg3 xl2 >
    <v-card :href="data.url" target="_blank" style="height: 100%;" >
      <v-card-media
      :src="data.public_image_url"
      height="200px"
      ></v-card-media>

      <v-card-title class="py-1" primary-title>
        <div>
          <h3 class="headline my-1">{{data.title}}</h3>
          <!-- <div>{{data.des}}</div> -->
        </div>
      </v-card-title>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  name: "",
  props: ['data'],
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
</style>
