<template>
  <v-layout>

    <v-container fluid grid-list-md fill-height>
      <v-layout row wrap>
        <VideoCard v-for="elem in videoArray" :data="elem" :key="elem.id" />

      </v-layout>
    </v-container>

  </v-layout>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
import 'firebase/firestore';
import VideoCard from "@/components/VideoCard"

export default {
  name: 'home',
  data: () => ({
      videoArray: []
  }),
  methods: {
    getData() {
      const db = firebase.firestore();
      const settings = { timestampsInSnapshots: true};
      db.settings(settings);

      let dateTime = new Date();
      dateTime.setMonth(dateTime.getMonth() - 2)
      const timestamp = Math.floor(dateTime / 1000);
      db.collection("list").where("uploadDate", ">", timestamp).orderBy("uploadDate", "desc").limit(150).get().then(querySnapshot => {
        let array = []
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data())
          // console.log(doc.id, " => ", doc.data());
        });
        this.videoArray = array

      })
    }
  },
  components: {
    VideoCard
  },
  created() {
    //do something after creating vue instance
    this.getData()
    const vm = this
    setInterval(function(){ vm.getData() }, 30 * 60 * 1000);
  }
}
</script>
