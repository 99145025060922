import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import firebase from 'firebase';
import 'firebase/firestore';

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(Vuetify)

const config = {
  apiKey: "AIzaSyDW9LtAGDoGFL95-5jo2pK1E3exf1UmY8I",
  authDomain: "akb48-bili.firebaseapp.com",
  databaseURL: "https://akb48-bili.firebaseio.com",
  projectId: "akb48-bili",
  storageBucket: "akb48-bili.appspot.com",
  messagingSenderId: "502076529450"
  };
firebase.initializeApp(config);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
